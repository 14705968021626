import React from "react"

import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import { ButtonLight } from "../shared/Button/button"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sivua ei löytynyt :(" />
    <div style={{
      minHeight: "30vh",
      paddingTop: "10vh",
      paddingBottom: "50px",
      margin: "0 auto",
    }}>
      <h1>Sivua ei löytynyt</h1>
      <p>
        <ButtonLight to="/" key="Etusivulle">
          Takaisin etusivulle {"→"}
        </ButtonLight>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
